import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Snatch Grip Sotts Press 4×4`}</p>
    <p>{`Low `}<em parentName="p">{`(just below knee)`}</em>{` Hang Squat Snatch 4×4\\@75%1RM`}</p>
    <p>{`then,`}</p>
    <p>{`21-18-15-12-9-6-3 each:`}</p>
    <p>{`OHS (95/65)`}</p>
    <p>{`Calorie Assault Bike`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      